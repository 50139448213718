<template>
  <b-container class="get-quote" fluid>
    <div class="head-bg"></div>
    <div class="d-flex mt-4" style="position:relative;height:88px">
      <logo class="mr-4"/>
      <align-middle><h1>BROKER PORTAL || Policies</h1></align-middle>
    </div>

    <b-row>
      <b-col cols="12" class="p-5 content-column">
        <div class="d-flex mt-1 pt-3 pl-3 table-tab-nav">

          <h3 class="mr-5" :class="{active:selection==='all'}" @click="selection='all'">
            <a>
              All
              <b-badge :variant="selection==='all'?'primary':'info'" class="mb-1">{{ all.length }}</b-badge>
            </a>
          </h3>
          <h3 class="mr-5" :class="{active:selection==='active'}" @click="selection='active'">
            <a>
              Active Policies
              <b-badge  :variant="selection==='active'?'primary':'info'" class="mb-1">{{ active.length }}</b-badge>
            </a>
          </h3>
          <h3 class="mr-5 flex-fill" :class="{active:selection==='binding'}" @click="selection='binding'">
            <a>
              Open Binding Conditions
              <b-badge  :variant="selection==='binding'?'primary':'info'" class="mb-1">{{ binding.length }}</b-badge>
            </a>
          </h3>
          <h3 class="mr-0" :class="{active:selection==='canceled'}" @click="selection='canceled'">
            <a>
              Canceled / Expired Policies
              <b-badge  :variant="selection==='canceled'?'primary':'info'" class="mb-1">{{ canceled.length }}</b-badge>
            </a>
          </h3>

        </div>

        <div class="table-panel">
          <div class="pl-2 mt-4 pt-2 d-flex search-bar" v-if="!fetching">
          <b-input-group style="width:222px;" class="mr-2">
            <b-input-group-prepend>
              <b-button>
                <i class="fas fa-search"></i>
              </b-button>
            </b-input-group-prepend>
            <b-input placeholder="Search" v-model="filterBy"></b-input>

          </b-input-group>
            <b-button id="colfilter"><i class="fas fa-sliders-h"/> </b-button>
            <b-popover target="colfilter" triggers="hover">
              <template #title>Choose Columns</template>
              <b-checkbox v-for="c in columnModel" :key="c.key" v-model="c.show">
                <strong style="color:white">{{titleCased(c.key)}}</strong>
              </b-checkbox>
            </b-popover>
            <div class="flex-fill">
              <div class="d-flex ml-4">
                <b-check v-model="filter.effective.show" switch style="position:relative;top:5px">
                  Effective between
                </b-check>

                <span class="ml-3" v-if="filter.effective.show">
                  <b-datepicker v-model="filter.effective.after" :max="filter.effective.before"/>
                </span>
                <span class="ml-3" v-if="filter.effective.show" style="position:relative;top:5px">
                  and
                </span>
                <span class="ml-3" v-if="filter.effective.show">
                  <b-datepicker v-model="filter.effective.before"
                                :min="filter.effective.after"/>
                </span>
                <div class="flex-fill invis">fill</div>
              </div>
            </div>
          </div>
          <div class="d-flex mt-4 mb-5" v-if="fetching">
            <div class="flex-fill"></div>
            <div class="w-25 pb-4 mt-5 mb-4">
              <progress-bar>Loading All Policies</progress-bar>

            </div>
            <div class="flex-fill"></div>
          </div>

          <b-table
              v-else
              class="mt-0" table-variant="secondary"
              striped bordered  :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :items="items" :fields="fields"
              :per-page="perPage" :current-page="currentPage"
              small
          >
            <template #head()="scope">
              <div>{{ scope.label }} <i class="desc fas fa-arrow-down"></i><i class="asc fas fa-arrow-up"></i> </div>
            </template>
            <template #cell(policyNum)="data">
              <router-link :to="{path:`/policy/${data.item.id}/${formatShortDate(data.item.transDate)}`}" class="text-primary ellipsize">
                {{ data.value.toUpperCase() }}
              </router-link>
            </template>
            <template #cell(premium)="data">
              <span>$ {{ currency(data.value,false,false) }}</span>
            </template>
            <template #cell(status)="data">
              <span>{{ titleCased(data.value) }}</span>
            </template>
            <template #cell(term)="data">
              <span>{{ formatOsDate(data.value) }} - {{ formatOsDate(data.item.expiry) }}</span>
            </template>
            <template #cell(modified)="data">
              <span>{{ formatOsDate(data.value) }}</span>
            </template>
            <template #cell(created)="data">
              <span>{{ formatOsDate(data.value) }}</span>
            </template>
            <template #cell(effective)="data">
              <span>{{ formatOsDate(data.value) }}</span>
            </template>
            <template #cell(expiry)="data">
              <span>{{ formatOsDate(data.value) }}</span>
            </template>


          </b-table>
          <div class="d-flex page-controls" v-if="!fetching">

            <div class="ml-2">
              <b-dropdown :text="`Show ${perPage}`" class="mr-2">
                <b-dropdown-item v-for="n in [10,15,20,25,50,100,200]" :key="n" @click="perPage=n">{{n}}</b-dropdown-item>
              </b-dropdown>
              Showing {{ startItem }} - {{ (startItem - 1) + perPage }} of {{ rows }}
            </div>
            <div class="flex-fill">
              <div class="d-flex" v-if="paging">
                <div class="w-25 text-right"></div>
                <div class="w-50 text-center">
                  <progress-bar style="max-width:888px" class="compressed mr-5 ml-5" variant="info">Retrieving Policies</progress-bar>
                </div>
                <div class="w-25"></div>
              </div>
            </div>
            <div class="text-right mr-2">
              <b-pagination
                  variant="info"
                  pills
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
              ></b-pagination>
            </div>


          </div>
        </div>

      </b-col>
    </b-row>

  </b-container>

</template>

<script>

import Logo from '@/components/svgs/logo';
import AlignMiddle from '@/components/util/align-middle';
import {policyCacheVersion} from '@/lib/fields/field-constants';
import {mapGetters, mapMutations, mapState} from 'vuex';
import {isType} from '@/lib/mytype';
import ProgressBar from '@/components/util/progress-bar';
import {formatOsDate} from '@/lib/util';
import {datadogLogs} from '@datadog/browser-logs';
import {parseOsDate, formatShortDate} from '@/lib/util';

export default {
  components: {ProgressBar, AlignMiddle, Logo},
  data: () => {
    let listModel, sortBy, sortDesc, perPage, currentPage,
      selection, version, showAll, cached = {}, filter;
    let defaultModel =  {
      policyId: false,
      policyNum: true,
      accountName: true,
      commercialName: false,
      accountNumber: true,
      modified: true,
      term: true,
      status: true,
      premium: true,
      policyType: true,
      product: false,
      partnerId: false
    };

    let validStickyState = false;
    let tableState;


    if (localStorage.getItem('policyListTable')) {
      tableState = JSON.parse(localStorage.getItem('policyListTable'));
      version = tableState.version;
      validStickyState = version === policyCacheVersion;
    }
    if (validStickyState){
      listModel = tableState.listModel;
      sortBy = tableState.sortBy;
      selection = tableState.selection;
      sortDesc = tableState.sortDesc;
      perPage = tableState.perPage;
      currentPage = tableState.currentPage;
      showAll = tableState.showAll;
      cached = tableState.cached;
      filter = tableState.filter;
    }
    if (!validStickyState) {
      sortBy = 'modified';
      sortDesc = true;
      perPage = 15;
      currentPage = 1;
      selection = 'all';
      version = policyCacheVersion;
      listModel = defaultModel;
      showAll = false;
      filter = {
        effective: {
          show: false,
          after: null,
          before: new Date()
        }
      };
    }

    return {
      columnModel: Object.entries(listModel).map(([key, show]) => {
        return {
          key, show,
          sortable: true
        };
      }),
      selection, sortBy, sortDesc,
      perPage, currentPage, version,
      filter, showAll, cached, filterBy: '',
      fetching: false,
      paging: false,
      bg: 'white'
    };
  },
  computed: {
    ...mapGetters('getQuote', ['policyList']),
    ...mapState('getQuote', ['policies', 'env']),
    xformed(){

      let filtered = this.policyList;
      /*if (!this.showAll && this.oktaUser){
        filtered = filtered.filter(q => q.producer === this.oktaUser.name);
      }*/
      if (this.filter.effective.show && this.filter.effective.after){
        let after = new Date(this.filter.effective.after);
        //after.setDate(after.getDate() - 1);
        let before = new Date(this.filter.effective.before);
        before.setDate(before.getDate() + 1);
        filtered = filtered.filter(q => q.effective >= after && q.effective <= before);
      }
      return filtered;//.map(map);
    },
    stickyTriggers(){
      let {showAll, cached, filter} = this;
      return {showAll, cached, filter};
    },
    curPageTriggers(){
      let {selection, sortBy, sortDesc, perPage} = this;
      return {selection, sortBy, sortDesc, perPage};
    },
    items(){
      let sel = this[this.selection];
      let search = this.filterBy.toLowerCase();
      let filterFields = ['accountName', 'policyNum', 'status', 'policyType'];
      let inFilter = item => {
        let found = filterFields.find(filt => {
          try {
            if ((`${item[filt]}`).toLowerCase().includes(search)) {
              return true;
            }
            return null;
          }catch(ex){
            return false;
          }
        });
        return !!found;
      };

      return sel ? sel.filter(item => !search.length || inFilter(item)) : this.all;
    },
    binding(){
      return this.filterList(['Expir', 'Cancel', 'Active', 'Rewritten'], true);
    },
    canceled(){
      return this.filterList(['Expir', 'Cancel']);
    },
    filterList(){
      return (substrings, invert = false) => {
        substrings = substrings.map(s => s.toLowerCase());
        let included = s => substrings.find(sub => s.toLowerCase().includes(sub));
        return invert ?
          this.xformed.filter(q => !included(q.status)) :
          this.xformed.filter(q => included(q.status));
      };
    },
    all(){
      return this.xformed;
    },
    active(){
      return this.filterList(['Expir', 'Cancel'], true);
    },
    fields(){
      return this.columnModel.filter(c => c.show);
    },
    startItem(){
      return Math.max(0, ((this.currentPage - 1) * this.perPage) + 1);
    },
    rows() {
      return this.items.length;
    }
  },
  methods: {
    ...mapMutations('getQuote', ['addPolicies']),
    formatShortDate(...args) {
      return formatShortDate(...args);
    },
    formatOsDate(...args) {
      return formatOsDate(...args);
    },
    stickyState(){
      let listModel = Object.fromEntries(this.columnModel.map(({key, show}) => [key, show]));
      let quoteListState = {
        version: this.version,
        listModel,
        ...this.curPageTriggers,
        currentPage: this.currentPage,
        showAll: this.showAll,
        cached: this.cached,
        filter: this.filter
      };

      localStorage.setItem('policyListTable', JSON.stringify(quoteListState));
    },
    getList(){
      this.fetching = true;
      const listResponse = (result) => {
        if (result?.response?.hasErrors){
          datadogLogs.logger.error(`policyList HasErrors`, {params, response: result.response});
        }
        let {fields, pageInfo} = result?.response;
        let list = fields.find(f => f.chain === 'policyList.items').val;

        (Array.isArray(list) ? list : [list]).forEach(p => {
          ['effective', 'expiry', 'created', 'modified'].forEach(prop => p[prop] = parseOsDate(p[prop]));
          p.transDate = p.effective;
          p.term = p.effective;
          let prem = isType.string(p.premium) ? p.premium.replace(/[^0-9.]/g, '') : 0;
          p.premium = Number(prem);
          p.status = p.status ?? '';
        });
        this.addPolicies({list});
        this.fetching = false;
        this.paging = pageInfo.isCallback;
      };
      let params = {eachPage: listResponse};
      if (this.cached[this.apiEnv]){
        let date = new Date(this.clone(this.cached[this.apiEnv]));
        date.setDate(date.getDate() - 1);
        params.modified = date;
      }
      this.oneShield('getPolicyList', params).then(listResponse).catch(reason => {
        datadogLogs.logger.error(`policyListError`, {params, error: reason});
        console.warn({reason});
      });
    }
  },
  mounted() {
    this.oneShield('redButton', {opName: 'getPolicyList', opCode: false});
    document.title = 'Policy List (LIO Insurance)';
    this.getList();
  },
  beforeDestroy() {
    this.oneShield('redButton', {opName: 'getPolicyList', opCode: true});
  },
  name: 'Policies',
  watch: {
    apiEnv(env){
      this.setAny({env});
    },
    stickyTriggers: {
      handler(){
        this.stickyState();
      },
      deep: true
    },

    columnModel: {
      handler() {
        this.stickyState();
      },
      deep: true
    },
    curPageTriggers: {
      handler() {
        this.currentPage = 1;
        this.stickyState();
      },
      deep: true
    },
    currentPage(){
      this.stickyState();
    }
  }
};
</script>

<style  lang="scss">
@import "../assets/scss/variables";
h3.popover-header{
  color:white;
}
#app {

  .content-column {
    margin-top:-28px;
    .table-tab-nav {
      h3 {
        color: $navy;
        font-size: 15px;
        &.active {
          color: $primary;
        }

        a .badge {
          font-size: 15px;
          position: relative;
          top: 0px;
        }
      }
      margin-bottom: -12px;
    }
    div.search-bar{
      border-bottom:solid 1px $gray;
      padding-bottom: 6px;
    }
    div.table-panel {


      background-color: white;
      box-shadow: 0 12px 25px $gray;

      div.page-controls {
        padding-top: 6px;
        margin-top: -10px;
        border-top: solid 1px $gray;
      }

      table.table-secondary {

        thead {
          tr {
            th{
              background-image: none;
              &[aria-sort=ascending]{
                i.asc{display:inline-block}
              }
              &[aria-sort=descending]{
                i.desc{display:inline-block}
              }
              i{display:none;}
            }
            th:nth-child(1) {
              padding-left: 12px
            }

            font-size: 16px;
          }
        }

        tbody {
          tr:nth-child(odd) {
            background: $light;
          }

          tr:nth-child(even) {
            background: #fff;
          }

          td, th {
            border-color: transparent;
          }

          td:nth-child(1) {
            padding-left: 12px
          }
        }
      }

      .b-pagination-pills {
        .page-item {
          &[role=separator]{display:none}
          .page-link {
            color: $light;
            border-radius: 4px !important;

          }

        }
      }
    }
  }
}
</style>
